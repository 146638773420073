import React, { useState } from 'react'
import OrderBanner from 'components/orders/orderBanner/OrderBanner';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ImportCostsAndTaxes from './ImportCostsAndTaxes/ImportCostsAndTaxes';
import ProductComEx from './ProductComEx/ProductComEx';

// TabPanel component to display the corresponding tab content
function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} {...other}>
            {value === index && (
                <Card extra={'w-full h-full bg-white'}>
                    {children}
                </Card>
            )}
        </div>
    );
}

const OrderView = (props) => {
    const { order } = props;
    const [activeTab, setActiveTab] = useState(0);
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Box mt={3}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <OrderBanner order={order} />
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={activeTab} onChange={handleTabChange}>
                            <Tab label="Costs and Taxes" />
                            <Tab label="Tax by Product" />
                            {/* <Tab label="Delivery" /> */}
                            {/* <Tab label="Payments" /> */}
                            {/* <Tab label="Additional Costs" /> */}
                        </Tabs>
                    </Box>
                    <TabPanel value={activeTab} index={0}>
                        <ImportCostsAndTaxes order={order}/>
                    </TabPanel>
                    <TabPanel value={activeTab} index={1}>
                        <ProductComEx order={order}/>
                    </TabPanel>
                </Grid>
            </Grid>
        </Box>
    )
}

export default OrderView