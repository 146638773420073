import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { BsFillEyeFill, BsLightningFill } from 'react-icons/bs';
import { useFetchData, useUpdateData, useFetchActionData } from 'services/queries';
import useAuth from "hooks/useAuth";
import Skeleton from '@mui/material/Skeleton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Chip from '@mui/material/Chip';
import { STATUS_CONFIG } from 'constants';
import toast from 'react-hot-toast';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CloseIcon from '@mui/icons-material/Close';
import { alpha, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { filter } from 'jszip';

const LoadingPlaceholder = () => (
  <Box sx={{ p: 2 }}>
    <Skeleton variant="rectangular" height={56} sx={{ mb: 2 }} />
    <Skeleton variant="rectangular" height={400} />
  </Box>
);

const ComExOrdersList = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    const [searchText, setSearchText] = useState('');
    const [filteredOrders, setFilteredOrders] = useState([]);

    const { data: orders, error: errorOrders, isLoading: isLoadingOrders, refetch: refetchOrders } =
        useFetchData('order/simple-sales-order', true);
    const {
        data: taxCalculations,
        error: errorTaxCalcs,
        isLoading: isLoadingTaxCalcs,
        refetch: refetchTaxCalculations
    } = useFetchData('tax/order-tax-calculation', true);

    const { auth, authLoading } = useAuth();
    const [isChecking, setIsChecking] = useState(false);
    const [prerequisites, setPrerequisites] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [orderId, setOrderId] = useState(null);

    // Create a function to calculate taxes
    const calculateTaxes = useUpdateData('tax/order-tax-calculation/calculate');

    // Use the new function for direct API calls
    const {
        data: prereqData,
        isLoading: isPrereqLoading,
        error: prereqError,
        refetch: refetchPrereq
    } = useFetchActionData(orderId ? `tax/prerequisites/${orderId}/` : null);

    // Filter orders based on search text
    useEffect(() => {
      if (orders) {
        if (!searchText) {
          setFilteredOrders(orders);
        } else {
          const lowercasedFilter = searchText.toLowerCase();
          const filtered = orders.filter(order => {
            return (
              order.order_identifier?.toLowerCase().includes(lowercasedFilter) ||
              order.customer_ref?.toLowerCase().includes(lowercasedFilter) ||
              order.buyer?.social_reason?.toLowerCase().includes(lowercasedFilter)
            );
          });
          setFilteredOrders(filtered);
        }
      }
    }, [orders, searchText]);

    // Function to check prerequisites before calculating taxes
    const checkPrerequisites = async (id) => {
        setIsChecking(true);
        setOrderId(id); // This will trigger the prerequisites fetch
    };

    // Watch for changes in prereqData
    useEffect(() => {
        if (!isPrereqLoading && prereqData && orderId) {
            setIsChecking(false);

            if (!prereqData.can_calculate) {
                // Show prerequisites dialog with detailed information
                setPrerequisites(prereqData);
                setOpenDialog(true);
            } else {
                // If prerequisites are met, proceed with calculation
                calculateTaxes.mutate(
                    { id: orderId },
                    {
                        onSuccess: () => {
                            toast.success("Tax calculation started successfully");
                            // Refetch tax calculations data to update the UI
                            setTimeout(() => {
                                refetchTaxCalculations();
                            }, 1000); // Small delay to allow the backend to process
                        },
                        onError: (error) => {
                            toast.error(`Error calculating taxes: ${error.message}`);
                        }
                    }
                );
            }

            // Reset orderId after handling
            setOrderId(null);
        }
    }, [prereqData, isPrereqLoading, orderId]);

    useEffect(() => {
        if (prereqError) {
            setIsChecking(false);
            toast.error("Failed to check prerequisites");
            setOrderId(null);
        }
    }, [prereqError]);

    const handleCalculateTaxes = (id) => {
        checkPrerequisites(id);
    };

    // Function to check if an order has tax calculation
    const hasOrderTaxCalculation = (orderId) => {
        if (!taxCalculations) return false;
        return taxCalculations.some(calc => calc.sales_order === orderId);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setPrerequisites(null);
    };

    const handleRefresh = () => {
        refetchOrders();
        refetchTaxCalculations();
        toast.success("Data refreshed");
    };

    if (isLoadingOrders || isLoadingTaxCalcs || authLoading) {
        return <LoadingPlaceholder />;
    }

    if (errorOrders || errorTaxCalcs) {
        return (
            <Box sx={{ p: 2 }}>
                <Alert
                  severity="error"
                  variant="filled"
                  sx={{
                    boxShadow: theme.shadows[4],
                    '& .MuiAlert-icon': {
                      fontSize: 28,
                      alignItems: 'center'
                    }
                  }}
                >
                    <AlertTitle>Error Loading Orders</AlertTitle>
                    {errorOrders?.message || errorTaxCalcs?.message || "An unexpected error occurred."}
                </Alert>
            </Box>
        );
    }

    const columns = [
        {
            field: 'actions',
            headerName: 'Actions',
            width: isMobile ? 70 : 110,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            filterable: false,
            aggregable: false,
            groupable: false,
            renderCell: (params) => {
                const { id } = params.row;
                const hasTaxCalculation = hasOrderTaxCalculation(id);

                return (
                    <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                        <Tooltip title={hasTaxCalculation ? "View Tax Calculation" : "No calculation available"} arrow>
                            <span>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        if (hasTaxCalculation) {
                                            window.location.assign(`/admin/comex/order/detail/${id}`);
                                        }
                                    }}
                                    disabled={!hasTaxCalculation}
                                    sx={{
                                        color: hasTaxCalculation ? theme.palette.primary.main : theme.palette.action.disabled,
                                        backgroundColor: hasTaxCalculation
                                            ? alpha(theme.palette.primary.main, 0.1)
                                            : 'transparent',
                                        '&:hover': {
                                            backgroundColor: hasTaxCalculation
                                                ? alpha(theme.palette.primary.main, 0.2)
                                                : 'transparent'
                                        }
                                    }}
                                >
                                    <BsFillEyeFill />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Run Tax Calculation" arrow>
                            <IconButton
                                size="small"
                                onClick={() => handleCalculateTaxes(id)}
                                disabled={isChecking}
                                color="warning"
                                sx={{
                                    backgroundColor: alpha(theme.palette.warning.main, 0.1),
                                    '&:hover': {
                                        backgroundColor: alpha(theme.palette.warning.main, 0.2)
                                    }
                                }}
                            >
                                <BsLightningFill />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                );
            }
        },
        {
            field: 'order_identifier',
            headerName: 'Reference',
            width: isMobile ? 80 : 100,
            editable: false,
            aggregable: false,
            groupable: false
        },
        {
            field: 'customer_ref',
            headerName: 'Cust. Ref.',
            width: isMobile ? 80 : 100,
            editable: false,
            aggregable: false,
            groupable: false,
        },
        {
            field: 'social_reason',
            headerName: 'Customer',
            minWidth: 180,
            flex: 1,
            editable: false,
            aggregable: false,
            groupable: false,
        },
        {
            field: 'issue_date',
            headerName: 'Issue Date',
            type: 'date',
            width: isMobile ? 90 : 110,
            aggregable: false,
            groupable: false,
            valueGetter: (value, row) => {
                return row?.issue_date ? new Date(row.issue_date) : null;
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 130,
            aggregable: false,
            groupable: false,
            renderCell: (params) => {
                var status = params?.value
                const config = STATUS_CONFIG[status] || {
                    color: 'default',
                    icon: null
                };
                return (
                    <Chip
                        label={status}
                        color={config.color}
                        size="small"
                        variant="filled"
                        sx={{ fontWeight: 500, minWidth: 85 }}
                    />
                );
            }
        },
        {
            field: 'tax_status',
            headerName: 'Tax Status',
            width: 130,
            aggregable: false,
            groupable: false,
            renderCell: (params) => {
                const hasTaxCalc = hasOrderTaxCalculation(params.row.id);
                return (
                    <Chip
                        icon={hasTaxCalc ? <CheckCircleIcon fontSize="small" /> : <ReportProblemIcon fontSize="small" />}
                        label={hasTaxCalc ? "Calculated" : "Pending"}
                        color={hasTaxCalc ? "success" : "default"}
                        size="small"
                        variant="filled"
                        sx={{ fontWeight: 500, minWidth: 110 }}
                    />
                );
            },
            valueGetter: (value, row) => {
                const hasTaxCalc = hasOrderTaxCalculation(row.id);
                return hasTaxCalc ? "Calculated" : "Pending";
            }
        },
    ];

    // Remove tax_status column on mobile devices
    if (isMobile) {
        columns.pop();
    }

    return (
        <Fade in timeout={500}>
            <Card
                elevation={3}
                sx={{
                    borderRadius: 2,
                    overflow: 'hidden',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <CardContent sx={{ pb: 0, flexGrow: 0, bgcolor: theme.palette.background.paper }}>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        justifyContent="space-between"
                        alignItems={{ xs: 'stretch', sm: 'center' }}
                        spacing={2}
                        sx={{ mb: 2 }}
                    >
                        <Typography variant="h5" component="h1" fontWeight="bold">
                            Orders
                        </Typography>

                        <Stack
                            direction="row"
                            spacing={1}
                            sx={{ width: { xs: '100%', sm: 'auto' } }}
                        >
                            <TextField
                                placeholder="Search orders..."
                                size="small"
                                fullWidth={isMobile}
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize="small" />
                                        </InputAdornment>
                                    ),
                                    endAdornment: searchText && (
                                        <InputAdornment position="end">
                                            <IconButton size="small" onClick={() => setSearchText('')}>
                                                <CloseIcon fontSize="small" />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                sx={{ minWidth: { sm: 200 } }}
                            />
                            <Tooltip title="Refresh Data">
                                <IconButton onClick={handleRefresh}>
                                    <RefreshIcon />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </Stack>

                    <Divider sx={{ mx: -2, mb: 2 }} />
                </CardContent>

                <Box sx={{ p: 0, flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <DataGridPremium
                        rows={filteredOrders}
                        columns={columns}
                        pagination
                        pageSizeOptions={[10, 25, 50, 100]}
                        initialState={{
                            density: isMobile ? 'compact' : 'comfortable',
                            pinnedColumns: { left: ['actions'] },
                            pagination: { paginationModel: { pageSize: 25 } },
                            aggregation: {
                                model: {}  // Empty aggregation model
                            }
                        }}
                        disableAggregation
                        disableRowGrouping
                        autoHeight
                        disableRowSelectionOnClick
                        autosizeOptions={{
                            columns: isTablet ? ['order_identifier', 'customer_ref'] :
                                ['order_identifier', 'customer_ref', 'issue_date', 'status'],
                            includeOutliers: true,
                            includeHeaders: true,
                        }}
                        autosizeOnMount
                        sx={{
                            '& .MuiDataGrid-columnHeaderTitle': {
                                fontWeight: 'bold',
                            },
                            '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                                outline: 'none',
                            },
                            '& .MuiDataGrid-row:hover': {
                                backgroundColor: theme => alpha(theme.palette.primary.light, 0.08),
                            },
                            border: 'none',
                            height: '100%',
                            flexGrow: 1,
                        }}
                    />
                </Box>

                {/* Prerequisites Dialog */}
                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    maxWidth="md"
                    fullWidth
                    PaperProps={{
                        elevation: 6,
                        sx: { borderRadius: 2 }
                    }}
                    TransitionComponent={Fade}
                    transitionDuration={300}
                >
                    <DialogTitle sx={{
                        bgcolor: theme.palette.error.light,
                        color: theme.palette.error.contrastText,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                    }}>
                        <ErrorIcon />
                        Unable to Calculate Taxes
                    </DialogTitle>
                    <DialogContent sx={{ pt: 3 }}>
                        {prerequisites && (
                            <>
                                <Paper elevation={1} sx={{ p: 2, mb: 3, bgcolor: theme.palette.background.default, borderRadius: 2 }}>
                                    <Stack direction="row" spacing={1} alignItems="center" mb={1}>
                                        <Typography variant="subtitle1" fontWeight="bold">
                                            Order:
                                        </Typography>
                                        <Typography variant="subtitle1">{prerequisites.order_identifier}</Typography>
                                        <Chip label={prerequisites.order_type} size="small" color="primary" />
                                    </Stack>

                                    <Typography variant="body1" gutterBottom>
                                        {prerequisites.message}
                                    </Typography>
                                </Paper>

                                {prerequisites.missing_requirements && prerequisites.missing_requirements.length > 0 && (
                                    <Box>
                                        <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <ReportProblemIcon color="warning" />
                                            Missing Requirements
                                        </Typography>

                                        {prerequisites.missing_requirements.map((req, index) => (
                                            <Paper
                                                key={index}
                                                elevation={2}
                                                sx={{
                                                    mb: 2,
                                                    p: 2,
                                                    bgcolor: alpha(theme.palette.warning.light, 0.2),
                                                    borderLeft: `4px solid ${theme.palette.warning.main}`,
                                                    borderRadius: 1
                                                }}
                                            >
                                                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                                                    {req.message}
                                                </Typography>

                                                <Box sx={{ mt: 1, mb: 2, p: 1, bgcolor: alpha(theme.palette.background.paper, 0.5), borderRadius: 1 }}>
                                                    <Typography variant="body2" fontWeight="medium" color="text.secondary">
                                                        Required Action:
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        {req.user_action || "Please fill in the required information."}
                                                    </Typography>
                                                </Box>

                                                {req.missing_fields && req.missing_fields.length > 0 && (
                                                    <Box sx={{ mt: 2 }}>
                                                        <Typography variant="body2" fontWeight="medium">Missing Information:</Typography>
                                                        <List dense disablePadding sx={{ ml: 1 }}>
                                                            {req.missing_fields.map((field, idx) => (
                                                                <ListItem key={idx} disableGutters>
                                                                    <ListItemIcon sx={{ minWidth: 28 }}>•</ListItemIcon>
                                                                    <ListItemText
                                                                        primary={req.missing_field_labels && req.missing_field_labels[idx] || field}
                                                                    />
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    </Box>
                                                )}

                                                {req.products_with_issues && req.products_with_issues.length > 0 && (
                                                    <Box sx={{ mt: 2 }}>
                                                        <Typography variant="body2" fontWeight="medium">Products with Issues:</Typography>
                                                        <List dense disablePadding>
                                                            {req.products_with_issues.map((product, idx) => (
                                                                <ListItem
                                                                    key={idx}
                                                                    sx={{
                                                                        flexDirection: 'column',
                                                                        alignItems: 'flex-start',
                                                                        pl: 1,
                                                                        mb: 1,
                                                                        bgcolor: alpha(theme.palette.background.paper, 0.5),
                                                                        borderRadius: 1
                                                                    }}
                                                                >
                                                                    <ListItemText
                                                                        primary={product.product_name}
                                                                        primaryTypographyProps={{ fontWeight: 'medium' }}
                                                                    />
                                                                    <Box pl={2} width="100%">
                                                                        <Typography variant="body2" color="text.secondary" fontWeight="medium" gutterBottom>
                                                                            Missing Attributes:
                                                                        </Typography>
                                                                        <List dense disablePadding>
                                                                            {product.missing_attributes.map((attr, attrIdx) => (
                                                                                <ListItem key={attrIdx} dense disableGutters sx={{ py: 0.5 }}>
                                                                                    <ListItemIcon sx={{ minWidth: 28 }}>•</ListItemIcon>
                                                                                    <ListItemText
                                                                                        primary={product.missing_attribute_labels &&
                                                                                            product.missing_attribute_labels[attrIdx] || attr}
                                                                                    />
                                                                                </ListItem>
                                                                            ))}
                                                                        </List>
                                                                    </Box>
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    </Box>
                                                )}
                                            </Paper>
                                        ))}
                                    </Box>
                                )}
                            </>
                        )}
                    </DialogContent>
                    <DialogActions sx={{ px: 3, py: 2, borderTop: `1px solid ${theme.palette.divider}` }}>
                        <Button
                            onClick={handleCloseDialog}
                            variant="contained"
                            color="primary"
                            startIcon={<CloseIcon />}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Card>
        </Fade>
    );
};

export default ComExOrdersList;
