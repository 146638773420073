import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AuthProvider } from "contexts/AuthProvider";
import { LoadingProvider } from "contexts/LoadingContext";
import { NotificationProvider } from "contexts/NotificationContext";
import RequireAuth from "components/auth/RequireAuth";

import RTLLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import "assets/css/Plugins.css";

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import br from 'dayjs/locale/pt-br'; // Import Brazilian locale
import { LicenseInfo } from '@mui/x-license';
import { QueryClient, QueryClientProvider, QueryCache } from '@tanstack/react-query';
import { Toaster } from 'react-hot-toast';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { blue, indigo } from '@mui/material/colors';
import { ColorModeContext } from "contexts/ColorModeContext";
import 'dayjs/locale/de';
import { ptBR as pickersPtBR } from '@mui/x-date-pickers/locales';
import dayjs from 'dayjs';
LicenseInfo.setLicenseKey('b90fa3c110f3d9e1b427f461a7b89e88Tz0xMDAzMjksRT0xNzYxMDU1NzgwMDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y');

dayjs.locale('pt-br');

const App = () => {
  const queryClient = new QueryClient({
    queryCache: new QueryCache(),
  })

  // Use the ColorModeContext
  const { isDarkMode } = React.useContext(ColorModeContext);

  // Dynamically set the theme based on isDarkMode
  const theme = React.useMemo(
    () =>
      createTheme(
        {
          palette: {
            mode: isDarkMode ? 'dark' : 'light', // Set theme mode here
            primary: blue,
            secondary: indigo,
            gradient: {
              primary: '#0d47a1',
              secondary: '#60a5fa',
            }
          },
          typography: {
            fontFamily: 'Poppins, sans-serif',
            fontWeightLight: 300,
            fontWeightRegular: 400,
            fontWeightMedium: 500,
            fontWeightBold: 600,
          },
          components: {
            MuiDataGrid: {
              styleOverrides: {
                root: {
                  border: 'none',
                },
              },
            },
          },

        },
        // ptBR,
        pickersPtBR,
        // corePtBr,
      ),


    [isDarkMode],
  );

  return (
    <>
      <ThemeProvider theme={theme}>
        <Toaster
          position="bottom-center"
          reverseOrder={false}
        />
        <NotificationProvider>
          <LoadingProvider>
            <QueryClientProvider client={queryClient}>
              <AuthProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={br} >


                  <Routes>
                    <Route path="auth/*" element={<AuthLayout />} />

                    <Route element={<RequireAuth allowedRoles={["Administrator", "Staff", "Client Administrator", "Client Staff"]} />} >
                      <Route path="admin/*" element={<AdminLayout />} />
                      <Route path="rtl/*" element={<RTLLayout />} />
                      <Route path="/" element={<Navigate to="/admin/order/sales-order/tracking" replace />} />
                    </Route>

                  </Routes>

                </LocalizationProvider >
              </AuthProvider>
            </QueryClientProvider>
          </LoadingProvider>
        </NotificationProvider>
      </ThemeProvider>
    </>

  );
};

export default App;
