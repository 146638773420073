import React, { useState, useEffect } from 'react';
import { useFetchData } from 'services/queries';
import NcmModal from './NcmModal';

// This component handles the state and logic for the NCM modal
const NcmModalController = ({
  isOpen,
  onClose,
  onSave,
  initialProductId = null,
  initialNcmCode = ''
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [displaySearchTerm, setDisplaySearchTerm] = useState("");
  const [shouldFetch, setShouldFetch] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [formData, setFormData] = useState({
    ncm_code: '',
    ncm_external_code: ''
  });
  const [isSaving, setIsSaving] = useState(false);

  // Reset state when modal is opened
  useEffect(() => {
    if (isOpen) {
      setActiveStep(0);
      setSearchTerm("");
      setDisplaySearchTerm("");
      setSelectedRow(null);
      setShouldFetch(false);
      setFormData({
        ncm_code: '',
        ncm_external_code: ''
      });
    }
  }, [isOpen]);

  // Update display value when searchTerm changes
  useEffect(() => {
    if (searchTerm) {
      try {
        // This assumes formatNcmCode is available in the context where this component is used
        const formattedCode = searchTerm.replace(/\D/g, '').replace(/(\d{2})(?=\d)/g, '$1.');
        setDisplaySearchTerm(formattedCode);
      } catch (error) {
        setDisplaySearchTerm(searchTerm);
      }
    }
  }, [searchTerm]);

  // Search results fetching
  const {
    data: searchResults,
    isLoading: isSearching,
    error: searchError,
    refetch
  } = useFetchData(
    shouldFetch ? `tax/aduaneiras-proxy/${searchTerm}` : null,
    false,
    !!shouldFetch && searchTerm.length >= 8
  );

  const handleInputChange = (e) => {
    const cleanValue = e.target.value.replace(/\D/g, '').substring(0, 8);
    setSearchTerm(cleanValue);
    setSelectedRow(null);
  };

  const handleSearch = () => {
    if (!searchTerm || searchTerm.length < 8) return;
    setShouldFetch(true);
    setSelectedRow(null);
    setTimeout(() => {
      refetch();
    }, 0);
  };

  const handleSelectNcm = (result, index) => {
    setSelectedRow(index);
    setFormData({
      ncm_code: result.ncm.codigo,
      ncm_external_code: result.id || result['@id']
    });
  };

  const handleContinue = () => {
    setActiveStep(1);
  };

  const handleBack = () => {
    setActiveStep(0);
  };

  const handleSave = async () => {
    if (!formData.ncm_code) return;

    setIsSaving(true);
    try {
      await onSave(initialProductId, formData);
      handleClose();
    } catch (error) {
      console.error("Error saving NCM data:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    onClose();
    setActiveStep(0);
    setShouldFetch(false);
    setSearchTerm("");
    setDisplaySearchTerm("");
    setSelectedRow(null);
  };

  // Stepper steps
  const steps = [
    {
      label: 'Search NCM Code',
      description: 'Find the appropriate NCM code for this product'
    },
    {
      label: 'Review Selection',
      description: 'Review and confirm your NCM selection'
    }
  ];

  return (
    <NcmModal
      isOpen={isOpen}
      onClose={handleClose}
      activeStep={activeStep}
      steps={steps}
      searchTerm={searchTerm}
      displaySearchTerm={displaySearchTerm}
      handleInputChange={handleInputChange}
      handleSearch={handleSearch}
      isSearching={isSearching}
      searchError={searchError}
      searchResults={searchResults}
      selectedRow={selectedRow}
      handleSelectNcm={handleSelectNcm}
      handleContinue={handleContinue}
      handleBack={handleBack}
      handleSave={handleSave}
      isSaving={isSaving}
      formData={formData}
    />
  );
};

export default NcmModalController;
