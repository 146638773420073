import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { useFetchDataId } from 'services/queries';
import { useParams } from "react-router-dom";
import OrderView from 'components/orders/OrderView';

const ComExOrderDetails = () => {
  const { id } = useParams();
  const { data: order, error: errorOrder, isLoading: isLoadingOrder } = useFetchDataId('order/sales-order', id, true);

  if (isLoadingOrder) return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
      <CircularProgress />
    </Box>
  );

  if (errorOrder) return (
    <Box mt={3}>
      <Alert severity="error">Error! {errorOrder.message}</Alert>
    </Box>
  );


  return (
    <OrderView order={order} />
  );
};

export default ComExOrderDetails;
