import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

const OrderBanner = (props) => {
    const { order } = props;
    const theme = useTheme();

    return (
        <Box extra={"w-full h-full px-6 py-6"}>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={1}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    pb={3}
                    sx={{
                        width: '100%',
                        borderRadius: '16px',
                        background: `linear-gradient(to bottom right, ${theme.palette.gradient.primary}, ${theme.palette.gradient.secondary})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        px: { xs: 3, md: '50px' },
                        pb: { xs: 5, md: 5 },
                        pt: { xs: 5.5, md: 5.5 },
                        height: { md: '180px', '2xl': '190px' },
                        alignItems: { md: 'flex-start' },
                    }}
                >

                    <Typography
                        variant="h4"
                        color="white"
                        sx={{
                            fontSize: {
                                xs: '1.25rem',
                                sm: '1.5rem',
                                md: '2rem',
                                xl: '2.5rem'
                            },
                            fontWeight: 'bold',
                            mb: 0.5
                        }}
                    >
                        Order {order?.order_identifier}
                    </Typography>
                    {order?.buyer?.social_reason && (
                        <Typography
                            color="white"
                            variant="body2"
                        >
                            {order?.buyer.social_reason}
                        </Typography>
                    )}
                    {order?.customer_ref && (
                        <Typography
                            variant="body2"
                            color="white"
                        >
                            {order?.customer_ref}
                        </Typography>
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default OrderBanner