import { useState, useEffect } from "react";
import { useFetchData, useUpdateData } from 'services/queries';
import { formatNcmCode } from 'utils/formatters/ncmFormatter';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import {
    GridToolbar,
    DataGridPremium
} from '@mui/x-data-grid-premium';
import avatar from 'assets/img/avatars/avatar_bewise.png';
import Grid from '@mui/material/Unstable_Grid2';
import NcmModalController from 'components/ncm/NcmModalController';

const NcmTaxes = () => {
    const [tableData, setTableData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentProductId, setCurrentProductId] = useState(null);
    const [currentNcmCode, setCurrentNcmCode] = useState('');

    const updateProduct = useUpdateData('product');
    const { data: ncmTaxes, isLoading: isLoadingNcmTaxes, error: errorNcmTaxes, refetch: refetchNcmTaxes } = useFetchData('tax/ncm-tec', true);
    const { data: products, isLoading: isLoadingProducts, error: errorProducts, refetch: refetchProducts } = useFetchData('product', true);

    // merge tableData with ncmTaxes (ncm_tec.id = product.ncm_tec)
    useEffect(() => {
        if (ncmTaxes && products) {
            let mergedTableData = products.map((product) => {
                const ncmTax = ncmTaxes.find((ncmTax) => ncmTax.id === product.ncm_tec);
                return {
                    ...product,
                    ncm_code: ncmTax?.ncm_code,
                    ncm_description: ncmTax?.ncm_description,
                    unit_measure: ncmTax?.unit_measure,
                    destaque: ncmTax?.destaque,
                    tipo_destaque: ncmTax?.tipo_destaque,
                    ii: ncmTax?.ii,
                    // ii_normal removed
                    tipo_ii: ncmTax?.tipo_ii,
                    ipi: ncmTax?.ipi,
                    // ipi_normal removed
                    tipo_ipi: ncmTax?.tipo_ipi,
                    pis_pasep: ncmTax?.pis_pasep,
                    cofins: ncmTax?.cofins,
                    tipo_icms: ncmTax?.tipo_icms,
                };
            });
            setTableData(() => mergedTableData);
        }
    }, [ncmTaxes, products, updateProduct.isSuccess]);

    const openNcmModal = (productId, currentNcmCode) => {
        setCurrentProductId(productId);
        setCurrentNcmCode(currentNcmCode);
        setIsModalOpen(true);
    };

    const handleSaveNcm = async (productId, formData) => {
        if (!productId || !formData.ncm_code) return;

        try {
            const product = tableData.find(p => p.id === productId);
            const updatedProduct = {
                ncm_external_code: formData.ncm_external_code,
                ncm_code: formData.ncm_code,
                name: product?.name || '',
            };

            await updateProduct.mutateAsync({ id: productId, newData: updatedProduct });
            refetchNcmTaxes();
            refetchProducts();
        } catch (error) {
            console.error("Error saving NCM data:", error);
            throw error;
        }
    };

    const actions = {
        create: false,
        edit: true,
        delete: false,
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Product',
            groupable: false,
            aggregable: false,
            minWidth: 350,
            renderCell: (params) => {
              // ...existing code...
              if (params.rowNode.type === 'group') {
                return params.value;
              }
              const product = params.row;
              const name = product?.name || '';
              const picture = product?.picture || avatar;
              const sku = product?.sku || '';

              if (product) {
                return (
                  <Grid container alignItems="center" pr={2} style={{ display: 'flex', flexDirection: 'row' }}>
                    <Grid
                      xs={2}
                      mx={0.5} marginRight={1}
                      maxWidth={45}
                    >
                      {
                        picture ? (
                          <img src={picture} className="w-10 h-10 object-contain drop-shadow-md" />
                        ) : (
                          <div className="w-10 h-10 bg-gray-100"></div>
                        )
                      }
                    </Grid>
                    <Grid flex={1}
                      sx={{ lineHeight: 'normal' }} py={0.5}>
                      <Typography
                        variant="subtitle2"
                        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        {name}
                      </Typography>
                      <Typography variant="caption">{sku}</Typography>
                    </Grid>
                  </Grid>

                );
              }
              return '';
            }
        },
        // ncm_code
        {
            field: 'ncm_code',
            headerName: 'NCM Code',
            type: 'text',
            editable: false,
            groupable: false,
            aggregable: false,
            minWidth: 150,
            visibleTo: ['Administrator', 'Staff'],
            valueFormatter: (value) => formatNcmCode(value),
            renderCell: (params) => {
                const hasNcmCode = Boolean(params.value);

                return (
                    <Box sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Tooltip title={hasNcmCode ? "Edit NCM code" : "Assign NCM code to this product"}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => openNcmModal(params.row.id, params.value)}
                                sx={{
                                    fontFamily: 'monospace',
                                    fontWeight: 600,
                                    borderRadius: 1.5,
                                    position: 'relative',
                                    minWidth: '100px',
                                    paddingLeft: 2,
                                    paddingRight: 2,
                                    borderColor: hasNcmCode ? 'primary.main' : 'warning.light',
                                    color: hasNcmCode ? 'primary.main' : 'warning.dark',
                                    backgroundColor: hasNcmCode ? 'transparent' : 'rgba(255, 152, 0, 0.05)',
                                    transition: 'all 0.2s',
                                    '&:hover': {
                                        borderColor: hasNcmCode ? 'primary.dark' : 'warning.main',
                                        backgroundColor: hasNcmCode ? 'action.hover' : 'rgba(255, 152, 0, 0.1)',
                                        '& .action-icon': {
                                            opacity: 1,
                                            transform: 'translateX(0)'
                                        }
                                    }
                                }}
                                endIcon={
                                    <EditIcon
                                        fontSize="small"
                                        className="action-icon"
                                        sx={{
                                            opacity: hasNcmCode ? 0.4 : 0.7,
                                            transform: 'translateX(-3px)',
                                            transition: 'all 0.2s',
                                            mr: -0.5
                                        }}
                                    />
                                }
                            >
                                {hasNcmCode ? formatNcmCode(params.value) : 'Assign'}
                            </Button>
                        </Tooltip>
                    </Box>
                )
            },
        },
        // ncm_description
        {
            field: 'ncm_description',
            headerName: 'NCM Description',
            type: 'text',
            editable: false,
            groupable: false,
            aggregable: false,
            minWidth: 350,
            flex: 1,
            visibleTo: ['Administrator', 'Staff'],
        },
        // unit_measure.description
        {
            field: 'unit_measure',
            headerName: 'Unit Measure',
            type: 'text',
            editable: false,
            groupable: false,
            aggregable: false,
            minWidth: 100,
            visibleTo: ['Administrator', 'Staff'],
            valueGetter: (value, row) => {
                return row?.unit_measure?.description;
            }
        },
        // ii
        {
            field: 'ii',
            headerName: 'II',
            type: 'number',
            editable: false,
            groupable: false,
            aggregable: false,
            minWidth: 100,
            visibleTo: ['Administrator', 'Staff'],
            valueFormatter: (value) => {
                if (value == null) return '';
                return `${(value * 100).toFixed(2)}%`;
            }
        },
        // ipi
        {
            field: 'ipi',
            headerName: 'IPI',
            type: 'number',
            editable: false,
            groupable: false,
            aggregable: false,
            minWidth: 100,
            visibleTo: ['Administrator', 'Staff'],
            valueFormatter: (value) => {
                if (value == null) return '';
                return `${(value * 100).toFixed(2)}%`;
            }
        },

        // pis_pasep
        {
            field: 'pis_pasep',
            headerName: 'PIS/PASEP',
            type: 'number',
            editable: false,
            groupable: false,
            aggregable: false,
            minWidth: 100,
            visibleTo: ['Administrator', 'Staff'],
            valueFormatter: (value) => {
                if (value == null) return '';
                return `${(value * 100).toFixed(2)}%`;
            }
        },
        // cofins
        {
            field: 'cofins',
            headerName: 'COFINS',
            type: 'number',
            editable: false,
            groupable: false,
            aggregable: false,
            minWidth: 100,
            visibleTo: ['Administrator', 'Staff'],
            valueFormatter: (value) => {
                if (value == null) return '';
                return `${(value * 100).toFixed(2)}%`;
            }
        },
        // tipo_icms
        {
            field: 'tipo_icms',
            headerName: 'Tipo ICMS',
            type: 'text',
            editable: false,
            groupable: false,
            aggregable: false,
            minWidth: 100,
            visibleTo: ['Administrator', 'Staff'],
        },
        // destaque
        {
            field: 'destaque',
            headerName: 'Destaque',
            type: 'number',
            editable: false,
            groupable: false,
            aggregable: false,
            minWidth: 100,
            visibleTo: ['Administrator', 'Staff'],
        },
        // tipo_destaque
        {
            field: 'tipo_destaque',
            headerName: 'Tipo Destaque',
            type: 'text',
            editable: false,
            groupable: false,
            aggregable: false,
            minWidth: 120,
            visibleTo: ['Administrator', 'Staff'],
        },
    ];

    if (isLoadingNcmTaxes || isLoadingProducts) {
        return (
            <div className='h-100'>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                    <Typography variant="body1" align="center">
                        Loading...
                    </Typography>
                </div>
            </div>
        )
    }

    if (errorNcmTaxes || errorProducts) {
        return (
            <div className='h-100'>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                    <Typography variant="body1" align="center">
                        Error fetching data. Please try again.
                    </Typography>
                </div>
            </div>
        )
    }

    return (
        <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
            <div className='flex justify-between items-center'>
                <h3 className="pt-[5px] text-2xl font-bold text-navy-700 dark:text-white">NCM Taxes</h3>
            </div>
            <Box>
                <DataGridPremium
                    columns={columns}
                    rows={tableData}
                    autosizeOnMount={true}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                    pagination
                    pageSizeOptions={[10, 25, 50, 100]}
                />

                {/* Using our reusable NCM Modal Controller component */}
                <NcmModalController
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onSave={handleSaveNcm}
                    initialProductId={currentProductId}
                    initialNcmCode={currentNcmCode}
                />
            </Box>
        </div>
    );
};

export default NcmTaxes;