import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { formatNcmCode } from 'utils/formatters/ncmFormatter';

const NcmReview = ({ searchResults, selectedRow, onBack }) => {
  const selectedNcm = searchResults?.tec?.[selectedRow];
  if (!selectedNcm) return null;

  return (
    <Box sx={{ mt: 3 }}>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={onBack}
        sx={{ mb: 3 }}
      >
        Back to Search
      </Button>

      <Paper
        elevation={0}
        sx={{
          p: 3,
          mb: 4,
          borderRadius: 1.5,
          bgcolor: theme => theme.palette.mode === 'dark' ? 'rgba(144, 202, 249, 0.08)' : 'rgba(33, 150, 243, 0.04)',
          border: '1px solid',
          borderColor: theme => theme.palette.mode === 'dark' ? 'rgba(144, 202, 249, 0.5)' : 'rgba(33, 150, 243, 0.25)'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
          <ReceiptIcon color="primary" sx={{ mr: 2, fontSize: 32 }} />
          <Box>
            <Typography variant="h6" fontWeight="medium">{formatNcmCode(selectedNcm.ncm.codigo)}</Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mt: 0.5 }}>
              {selectedNcm.ncm.descricao}
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="text.secondary">NCM Code</Typography>
            <Typography variant="body1" fontWeight="medium">{formatNcmCode(selectedNcm.ncm.codigo)}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="text.secondary">Unit of Measure</Typography>
            <Typography variant="body1">{selectedNcm.ncm.unidadeMedida?.descricao || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>Description</Typography>
            <Typography variant="body1">{selectedNcm.ncm.descricao}</Typography>
          </Grid>
        </Grid>

        <Box sx={{ mt: 3, bgcolor: 'background.paper', p: 2, borderRadius: 1.5, border: '1px solid', borderColor: 'divider' }}>
          <Typography variant="body2" color="text.secondary">
            Once saved, this NCM code will be used for tax calculations and regulatory compliance for this product.
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default NcmReview;
