/**
 * Format NCM code to XX.XX.XXXX pattern
 * @param {string} ncmCode - Raw NCM code (e.g., "12345678" or "1234.5678")
 * @returns {string} - Formatted NCM code (e.g., "12.34.5678")
 */
export const formatNcmCode = (ncmCode) => {
  if (!ncmCode) return '';

  // Remove any non-digit characters
  const digitsOnly = ncmCode.replace(/\D/g, '');

  // If we don't have exactly 8 digits, return the original input
  if (digitsOnly.length !== 8) {
    return ncmCode;
  }

  // Format as XX.XX.XXXX
  return `${digitsOnly.substring(0, 4)}.${digitsOnly.substring(4, 6)}.${digitsOnly.substring(6)}`;
};

/**
 * Display formatted NCM code or fallback to a dash if not available
 * @param {string} ncmCode - Raw NCM code
 * @param {string} fallback - Fallback value if ncmCode is empty (default: 'N/A')
 * @returns {string} - Formatted NCM code or fallback
 */
export const displayNcmCode = (ncmCode, fallback = 'N/A') => {
  if (!ncmCode) return fallback;
  return formatNcmCode(ncmCode);
};
