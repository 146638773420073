import React from 'react'
import { useFetchData } from 'services/queries';
import Box from '@mui/material/Box';
import {
    DataGridPremium,
} from '@mui/x-data-grid-premium';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import avatar from 'assets/img/avatars/avatar_bewise.png';
import { USDcurrencyFormatter } from 'utils';
import { BRLcurrencyFormatter, NCMFormatter } from 'utils';

const ProductComEx = (props) => {
    const { order } = props;
    const { data: dataProductComEx, error: errorDataProductComEx, isLoading: isLoadingDataProductComEx } = useFetchData(`order/product-item-tax/?sales_order=${order.id}`, true);
    if (isLoadingDataProductComEx) return <div>Loading...</div>;
    if (errorDataProductComEx) return <div>Error! {errorDataProductComEx.message}</div>;
    console.log("dataProductComEx: ", dataProductComEx)

    const columns = [
        {
            field: 'product',
            headerName: 'Product',
            groupable: false,
            aggregable: false,
            minWidth: 250,
            flex: 1,
            renderCell: (params) => {
                if (params.rowNode.isAutoGenerated) {
                    return null;
                }
                if (params.rowNode.type === 'group') {
                    return params.value;
                }
                const name = params.row?.product_name || '';
                const picture = params.row?.product_picture || avatar;
                const sku = params.row?.product_sku || '';

                return (
                    <Grid container alignItems="center" pr={2} style={{ display: 'flex', flexDirection: 'row' }}>
                        <Grid
                            xs={2}
                            mx={0.5} marginRight={1}
                            maxWidth={45}
                        >
                            {
                                picture ? (
                                    <img src={picture} className="w-10 h-10 object-contain drop-shadow-md" />
                                ) : (
                                    <div className="w-10 h-10 bg-gray-100"></div>
                                )
                            }
                        </Grid>
                        <Grid flex={1}
                            sx={{ lineHeight: 'normal' }} py={0.5}>
                            <Typography
                                variant="subtitle2"
                                style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                            >
                                {name}
                            </Typography>
                            <Typography variant="caption">{sku}</Typography>
                        </Grid>
                    </Grid>

                );
            }
        },
        // quantity
        {
            field: 'quantity',
            headerName: 'Qty',
            description: 'Quantity',
            width: 90,
            editable: false,
            groupable: false,
            type: 'number',
        },
        {
            field: 'ncm',
            headerName: 'NCM',
            width: 100,
            type: 'string',
            renderCell: (params) => NCMFormatter(params.value)
        },
        {
            field: 'unit_of_measure',
            description: 'Unit of Measure',
            headerName: 'Measure',
            width: 110,
        },
        {
            field: 'invoice_usd',
            headerName: 'Invoice',
            width: 100,
            type: 'number',
            renderCell: (params) => USDcurrencyFormatter.format(params.value)
        },
        {
            field: 'cfr',
            headerName: 'CFR',
            width: 100,
            type: 'number',
            renderCell: (params) => USDcurrencyFormatter.format(params.value)
        },
        {
            field: 'unit_disbursement',
            headerName: 'Unit Disb.',
            description: 'Unit Disbursement',
            width: 120,
            type: 'number',
            renderCell: (params) => BRLcurrencyFormatter.format(params.value)
        },
        {
            field: 'unit_cost',
            headerName: 'Unit Cost',
            width: 110,
            type: 'number',
            renderCell: (params) => BRLcurrencyFormatter.format(params.value)
        },
    ]

    return (
            <Box
                sx={{
                    // height: 500,
                    width: '100%',
                    '& .actions': {
                        color: 'text.secondary',
                    },
                    '& .textPrimary': {
                        color: 'text.primary',
                    },
                    '& .MuiDataGrid-root': {
                        border: 'none',
                    },
                }}
                p={1}
            >
                <DataGridPremium
                    rows={dataProductComEx}
                    columns={columns.map(column => ({
                        ...column,
                        editable: false
                    }))}
                    sx={{
                        '.MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold',
                        },
                    }}
                    disableAggregation={true}
                    getRowId={(row) => row.product_item_id}

                />
            </Box>
    )
}

export default ProductComEx