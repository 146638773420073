import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';
import Fade from '@mui/material/Fade';
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoneIcon from '@mui/icons-material/Done';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { formatNcmCode } from 'utils/formatters/ncmFormatter';


const formatTaxValue = (value) => {
  if (value === null || value === undefined) return '-';
  const numericValue = parseFloat(value);
  if (isNaN(numericValue)) return '-';
  return `${numericValue.toFixed(2)}%`;
};

const NcmSearch = ({
  searchTerm,
  displaySearchTerm,
  handleInputChange,
  handleSearch,
  isSearching,
  searchError,
  searchResults,
  selectedRow,
  handleSelectNcm,
  onContinue
}) => {
  // Get the selected item data
  const selectedItem = selectedRow !== null && searchResults?.tec ? searchResults.tec[selectedRow] : null;

  return (
    <Box sx={{ mt: 3 }}>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12} md={9}>
          <Box>
            <TextField
              fullWidth
              label="Search NCM Code"
              value={displaySearchTerm}
              onChange={handleInputChange}
              placeholder="XX.XX.XX.XX"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                ),
              }}
              sx={{ height: 56 }}
            />
            <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 0.5 }}>
              Enter all 8 digits to search for NCM codes
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <LoadingButton
            variant="contained"
            onClick={handleSearch}
            loading={isSearching}
            disabled={!searchTerm || searchTerm.length < 8}
            sx={{ borderRadius: 1.5, height: 56, width: '100%' }}
            startIcon={<SearchIcon />}
          >
            Search NCM
          </LoadingButton>
        </Grid>
      </Grid>

      {isSearching && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 4, py: 6 }}>
          <CircularProgress size={40} />
          <Typography variant="body1" sx={{ ml: 2, color: 'text.secondary' }}>
            Searching NCM...
          </Typography>
        </Box>
      )}

      {searchError && (
        <Alert severity="error" sx={{ mt: 3, borderRadius: 1.5 }}>
          <AlertTitle>Search Error</AlertTitle>
          {searchError.message}
        </Alert>
      )}

      <Fade in={!isSearching && searchResults?.tec && searchResults.tec.length > 0}>
        <Box sx={{ mt: 4 }}>
          {searchResults?.tec && searchResults.tec.length > 0 && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                <Typography variant="subtitle1" fontWeight="medium">
                  Search Results ({searchResults.tec.length})
                </Typography>
                <Chip
                  label="Select the best description"
                  size="small"
                  color="primary"
                  variant="outlined"
                  sx={{ borderRadius: 1.5 }}
                  icon={<ReceiptIcon fontSize="small" />}
                />
              </Box>

              <TableContainer
                component={Paper}
                elevation={0}
                sx={{
                  maxHeight: 400,
                  mb: 2,
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 1.5,
                  '& .MuiTableCell-head': {
                    backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)',
                    fontWeight: 600
                  }
                }}
              >
                <Table stickyHeader size="medium">
                  <TableHead>
                    <TableRow>
                      <TableCell>NCM Code</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Unit</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchResults.tec.map((result, index) => {
                      const isSelected = selectedRow === index;
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            '&:hover': { bgcolor: 'action.hover' },
                            ...(isSelected && {
                              bgcolor: theme => theme.palette.mode === 'dark' ? 'rgba(144, 202, 249, 0.08)' : 'rgba(33, 150, 243, 0.04)'
                            }),
                            cursor: 'pointer'
                          }}
                          onClick={() => handleSelectNcm(result, index)}
                        >
                          <TableCell sx={{ fontWeight: isSelected ? 500 : 400 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <ReceiptIcon
                                fontSize="small"
                                sx={{ mr: 1, color: isSelected ? 'primary.main' : 'action.active' }}
                              />
                              {formatNcmCode(result.ncm.codigo)}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">{result.ncm.descricao}</Typography>
                            <Box sx={{
                              display: 'flex',
                              gap: 1.5,
                              mt: 0.5,
                              flexWrap: 'wrap',
                              alignItems: 'center'
                            }}>
                              {result.ii !== null && result.ii !== undefined && (
                                <Chip
                                  label={`II: ${formatTaxValue(result.ii)}`}
                                  size="small"
                                  variant="outlined"
                                  sx={{ height: 20, fontSize: '0.7rem' }}
                                />
                              )}
                              {result.ipi !== null && result.ipi !== undefined && (
                                <Chip
                                  label={`IPI: ${formatTaxValue(result.ipi)}`}
                                  size="small"
                                  variant="outlined"
                                  sx={{ height: 20, fontSize: '0.7rem' }}
                                />
                              )}
                              {result.pisPasep !== null && result.pisPasep !== undefined && (
                                <Chip
                                  label={`PIS: ${formatTaxValue(result.pisPasep)}`}
                                  size="small"
                                  variant="outlined"
                                  sx={{ height: 20, fontSize: '0.7rem' }}
                                />
                              )}
                              {result.cofins !== null && result.cofins !== undefined && (
                                <Chip
                                  label={`COFINS: ${formatTaxValue(result.cofins)}`}
                                  size="small"
                                  variant="outlined"
                                  sx={{ height: 20, fontSize: '0.7rem' }}
                                />
                              )}
                            </Box>
                          </TableCell>
                          <TableCell>{result.ncm.unidadeMedida?.descricao || '-'}</TableCell>
                          <TableCell align="center">
                            <Button
                              variant={isSelected ? "contained" : "outlined"}
                              size="small"
                              color="primary"
                              sx={{ borderRadius: 1.5 }}
                              startIcon={isSelected ? <DoneIcon /> : <CheckCircleIcon />}
                            >
                              {isSelected ? "Selected" : "Select"}
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              {selectedRow !== null && (
                <>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onContinue}
                      startIcon={<DoneIcon />}
                      sx={{ borderRadius: 1.5 }}
                    >
                      Continue with Selected NCM
                    </Button>
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      </Fade>

      {searchResults?.tec && searchResults.tec.length === 0 && !isSearching && (
        <Alert
          severity="info"
          icon={<InfoIcon />}
          sx={{ mt: 3, borderRadius: 1.5 }}
        >
          <AlertTitle>No Results Found</AlertTitle>
          No NCM codes found matching your search. Try a different NCM code or check for typos.
        </Alert>
      )}
    </Box>
  );
};

export default NcmSearch;
