import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CalculateIcon from '@mui/icons-material/Calculate';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SavingsIcon from '@mui/icons-material/Savings';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import Chip from '@mui/material/Chip';
import { useFetchDataId } from 'services/queries';
import { useParams } from "react-router-dom";


const formatCurrency = (value, currency = 'BRL') => {
    if (value === 0) return '-';
    return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value);
};

const SectionHeader = ({ icon, title }) => (
    <Box display="flex" alignItems="center" mb={2}>
        {icon}
        <Typography variant="h6" sx={{ ml: 1 }}>
            {title}
        </Typography>
    </Box>
);

const FinancialRow = ({ label, valueBRL, valueUSD }) => (
    <TableRow>
        <TableCell component="th" scope="row">
            {label}
        </TableCell>
        <TableCell align="right">{formatCurrency(valueBRL, 'BRL')}</TableCell>
        <TableCell align="right">{formatCurrency(valueUSD, 'USD')}</TableCell>
    </TableRow>
);

const SummaryCard = ({ title, value, currency = 'BRL', color = 'primary.main', icon }) => (
    <Card variant="outlined" sx={{ height: '100%' }}>
        <CardContent>
            <Typography color="text.secondary" gutterBottom>
                {title}
            </Typography>
            <Typography variant="h5" component="div" sx={{ color }}>
                {formatCurrency(value, currency)}
            </Typography>
            {icon && <Box display="flex" justifyContent="center" mt={2}>{icon}</Box>}
        </CardContent>
    </Card>
);

const ExchangeRateCard = ({ exchangeRate }) => (
    exchangeRate = parseFloat(exchangeRate),
    <Card variant="outlined" sx={{ mb: 3, bgcolor: 'background.paper' }}>
        <CardContent>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                    <CurrencyExchangeIcon color="primary" sx={{ mr: 1 }} />
                    <Typography variant="h6">
                        Exchange Rate (USD to BRL)
                    </Typography>
                </Box>
                <Chip
                    label={`USD 1.00 = BRL ${typeof exchangeRate === 'number' && !isNaN(exchangeRate) ? exchangeRate.toFixed(2) : '-'}`}
                    color="primary"
                    variant="outlined"
                    sx={{ fontWeight: 'bold', fontSize: '1rem', px: 1 }}
                />
            </Box>
        </CardContent>
    </Card>
);

const ImportCostsAndTaxes = (props) => {
    const { id } = useParams();
    const { order } = props;

    const { data, error, isLoading } = useFetchDataId(`tax/order-tax-calculation/latest`, id, true);

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Alert severity="error" sx={{ mt: 2 }}>
                Error loading import costs and taxes data. Please try again later.
            </Alert>
        );
    }

    console.log(data);

    const taxCalculation = data;

    return (
        <Box p={2}>
            <Typography variant="h5" gutterBottom>Import Costs and Taxes</Typography>

            {/* Exchange Rate Card */}
            <ExchangeRateCard exchangeRate={taxCalculation.exchange_rate_dollar_to_real || order?.exchange_rate_dollar_to_real} />

            {/* Summary Cards - First Row */}
            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6} md={4}>
                    <SummaryCard
                        title="Total Customs Value"
                        value={taxCalculation.valor_aduaneiro_brl}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SummaryCard
                        title="Total Customs Taxes"
                        value={taxCalculation.tributos_alfandegarios_brl}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SummaryCard
                        title="Total Invoice Value"
                        value={taxCalculation.nota_fiscal_brl}
                    />
                </Grid>
            </Grid>

            {/* Summary Cards - Second Row */}
            <Grid container spacing={2} sx={{ mb: 4 }}>
                <Grid item xs={12} sm={6} md={4}>
                    <SummaryCard
                        title="Total Tax Credits"
                        value={taxCalculation.total_tax_credits_brl}
                        color="info.main"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SummaryCard
                        title="Total Disbursement"
                        value={taxCalculation.desembolso_total_importacao_brl}
                        color="info.dark"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SummaryCard
                        title="Final Import Cost"
                        value={taxCalculation.custo_total_importacao_brl}
                        color="success.main"
                    />
                </Grid>
            </Grid>

            {/* Customs Value Section */}
            <Card sx={{ mb: 3 }}>
                <CardContent>
                    <SectionHeader
                        icon={<AttachMoneyIcon color="primary" />}
                        title="Customs Value (Valor Aduaneiro)"
                    />
                    <TableContainer component={Paper} variant="outlined">
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Description</TableCell>
                                    <TableCell align="right">BRL</TableCell>
                                    <TableCell align="right">USD</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <FinancialRow
                                    label="FOB Value"
                                    valueBRL={taxCalculation.fob_value_brl}
                                    valueUSD={taxCalculation.fob_value_usd}
                                />
                                <FinancialRow
                                    label="International Freight Cost"
                                    valueBRL={taxCalculation.international_freight_cost_brl}
                                    valueUSD={taxCalculation.international_freight_cost_usd}
                                />
                                <FinancialRow
                                    label="International Insurance"
                                    valueBRL={taxCalculation.international_insurance_brl}
                                    valueUSD={taxCalculation.international_insurance_usd}
                                />
                                <TableRow sx={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
                                    <TableCell component="th" scope="row">
                                        <Typography fontWeight="bold">Total Customs Value</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography fontWeight="bold">{formatCurrency(taxCalculation.valor_aduaneiro_brl)}</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography fontWeight="bold">{formatCurrency(taxCalculation.valor_aduaneiro_usd, 'USD')}</Typography>
                                        </TableCell>
                                    </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>

            {/* Customs Taxes Section */}
            <Card sx={{ mb: 3 }}>
                <CardContent>
                    <SectionHeader
                        icon={<CalculateIcon color="primary" />}
                        title="Customs Taxes (Tributos Alfandegários)"
                    />
                    <TableContainer component={Paper} variant="outlined">
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Tax Type</TableCell>
                                    <TableCell align="right">BRL</TableCell>
                                    <TableCell align="right">USD</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <FinancialRow
                                    label="II (Import Tax)"
                                    valueBRL={taxCalculation.II_brl}
                                    valueUSD={taxCalculation.II_usd}
                                />
                                <FinancialRow
                                    label="IPI"
                                    valueBRL={taxCalculation.IPI_brl}
                                    valueUSD={taxCalculation.IPI_usd}
                                />
                                <FinancialRow
                                    label="PIS/PASEP"
                                    valueBRL={taxCalculation.PIS_PASEP_brl}
                                    valueUSD={taxCalculation.PIS_PASEP_usd}
                                />
                                <FinancialRow
                                    label="COFINS"
                                    valueBRL={taxCalculation.COFINS_brl}
                                    valueUSD={taxCalculation.COFINS_usd}
                                />
                                <FinancialRow
                                    label="Anti-Dumping"
                                    valueBRL={taxCalculation.antidumping_devido_brl}
                                    valueUSD={taxCalculation.antidumping_devido_usd}
                                />
                                <FinancialRow
                                    label="TUS (Unified Service Fee)"
                                    valueBRL={taxCalculation.tus_brl}
                                    valueUSD={taxCalculation.tus_usd}
                                />
                                <TableRow sx={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
                                    <TableCell component="th" scope="row">
                                        <Typography fontWeight="bold">Total Customs Taxes</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography fontWeight="bold">{formatCurrency(taxCalculation.tributos_alfandegarios_brl)}</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography fontWeight="bold">{formatCurrency(taxCalculation.tributos_alfandegarios_usd, 'USD')}</Typography>
                                        </TableCell>
                                    </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>

            {/* Client Invoice Section */}
            <Card sx={{ mb: 3 }}>
                <CardContent>
                    <SectionHeader
                        icon={<ReceiptIcon color="primary" />}
                        title="Client Entry Invoice (Nota Fiscal de Entrada)"
                    />
                    <TableContainer component={Paper} variant="outlined">
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Description</TableCell>
                                    <TableCell align="right">BRL</TableCell>
                                    <TableCell align="right">USD</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <FinancialRow
                                    label="ICMS Calculation Base"
                                    valueBRL={taxCalculation.bc_icms_entrada}
                                    valueUSD={taxCalculation.base_calculo_icms_usd}
                                />
                                <FinancialRow
                                    label="ICMS on Invoice"
                                    valueBRL={taxCalculation.icms_destaque_nf_brl}
                                    valueUSD={taxCalculation.icms_destaque_nf_usd}
                                />
                                <FinancialRow
                                    label="IPI on Invoice"
                                    valueBRL={taxCalculation.ipi_destaque_nf_brl}
                                    valueUSD={taxCalculation.ipi_destaque_nf_usd}
                                />
                                <FinancialRow
                                    label="ICMS-ST on Invoice"
                                    valueBRL={taxCalculation.icms_st_destaque_nf_brl}
                                    valueUSD={taxCalculation.icms_st_destaque_nf_usd}
                                />
                                {taxCalculation.icms_garantia_brl != 0 && (
                                    <FinancialRow
                                        label="ICMS Guarantee"
                                        valueBRL={taxCalculation.icms_garantia_brl}
                                        valueUSD={taxCalculation.icms_garantia_usd}
                                    />
                                )}
                                {taxCalculation.icms_entrada_brl != 0 && (
                                    <FinancialRow
                                        label="ICMS Entry"
                                        valueBRL={taxCalculation.icms_entrada_brl}
                                        valueUSD={taxCalculation.icms_entrada_usd}
                                    />
                                )}
                                <TableRow sx={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
                                    <TableCell component="th" scope="row">
                                        <Typography fontWeight="bold">Total Invoice Value</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography fontWeight="bold">{formatCurrency(taxCalculation.nota_fiscal_brl)}</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography fontWeight="bold">{formatCurrency(taxCalculation.nota_fiscal_usd, 'USD')}</Typography>
                                        </TableCell>
                                    </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>

            <Grid container spacing={3}>
                {/* Commercial Agreement Section */}
                <Grid item xs={12} md={6}>
                    <Card sx={{ height: '100%' }}>
                        <CardContent>
                            <SectionHeader
                                icon={<AccountBalanceIcon color="primary" />}
                                title="Commercial Agreement"
                            />
                            <TableContainer component={Paper} variant="outlined">
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Description</TableCell>
                                            <TableCell align="right">BRL</TableCell>
                                            <TableCell align="right">USD</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <FinancialRow
                                            label="Commercial Margin"
                                            valueBRL={taxCalculation.margem_comercial_brl}
                                            valueUSD={taxCalculation.margem_comercial_usd}
                                        />
                                        <TableRow sx={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
                                            <TableCell component="th" scope="row">
                                                <Typography fontWeight="bold">Total Import Disbursement</Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography fontWeight="bold">{formatCurrency(taxCalculation.desembolso_total_importacao_brl)}</Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography fontWeight="bold">{formatCurrency(taxCalculation.desembolso_total_importacao_usd, 'USD')}</Typography>
                                                </TableCell>
                                            </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Tax Credits Section */}
                <Grid item xs={12} md={6}>
                    <Card sx={{ height: '100%' }}>
                        <CardContent>
                            <SectionHeader
                                icon={<SavingsIcon color="primary" />}
                                title="Tax Credits"
                            />
                            <TableContainer component={Paper} variant="outlined">
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Credit Type</TableCell>
                                            <TableCell align="right">BRL</TableCell>
                                            <TableCell align="right">USD</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <FinancialRow
                                            label="IPI Credit"
                                            valueBRL={taxCalculation.credito_ipi_brl}
                                            valueUSD={taxCalculation.credito_ipi_usd}
                                        />
                                        <FinancialRow
                                            label="PIS/PASEP Credit"
                                            valueBRL={taxCalculation.credito_pis_pasep_brl}
                                            valueUSD={taxCalculation.credito_pis_pasep_usd}
                                        />
                                        <FinancialRow
                                            label="COFINS Credit"
                                            valueBRL={taxCalculation.credito_cofins_brl}
                                            valueUSD={taxCalculation.credito_cofins_usd}
                                        />
                                        <FinancialRow
                                            label="ICMS Credit"
                                            valueBRL={taxCalculation.credito_icms_brl}
                                            valueUSD={taxCalculation.credito_icms_usd}
                                        />
                                        <TableRow sx={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
                                            <TableCell component="th" scope="row">
                                                <Typography fontWeight="bold">Total Tax Credits</Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography fontWeight="bold" color="info.main">{formatCurrency(taxCalculation.total_tax_credits_brl)}</Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography fontWeight="bold" color="info.main">{formatCurrency(taxCalculation.total_tax_credits_usd, 'USD')}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        <TableRow sx={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
                                            <TableCell component="th" scope="row">
                                                <Typography fontWeight="bold">Final Import Cost</Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography fontWeight="bold" color="success.main">{formatCurrency(taxCalculation.custo_total_importacao_brl)}</Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography fontWeight="bold" color="success.main">{formatCurrency(taxCalculation.custo_total_importacao_usd, 'USD')}</Typography>
                                                </TableCell>
                                            </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}

export default ImportCostsAndTaxes;