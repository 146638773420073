import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import NcmSearch from './NcmSearch';
import NcmReview from './NcmReview';

const NcmModal = ({
  isOpen,
  onClose,
  activeStep,
  steps,
  searchTerm,
  displaySearchTerm,
  handleInputChange,
  handleSearch,
  isSearching,
  searchError,
  searchResults,
  selectedRow,
  handleSelectNcm,
  handleContinue,
  handleBack,
  handleSave,
  isSaving,
  formData
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '95%', sm: '80%', md: '70%', lg: '60%' },
          maxWidth: 900,
          maxHeight: '90vh',
          overflowY: 'auto',
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: { xs: 2, sm: 4 },
        }}>
          <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
            {activeStep === 0 ? 'Search for NCM Code' : 'Review NCM Selection'}
          </Typography>
          <Divider sx={{ mb: 3 }} />

          <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
            {steps.map((step) => (
              <Step key={step.label}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {activeStep === 0 && (
            <NcmSearch
              searchTerm={searchTerm}
              displaySearchTerm={displaySearchTerm}
              handleInputChange={handleInputChange}
              handleSearch={handleSearch}
              isSearching={isSearching}
              searchError={searchError}
              searchResults={searchResults}
              selectedRow={selectedRow}
              handleSelectNcm={handleSelectNcm}
              onContinue={handleContinue}
            />
          )}

          {activeStep === 1 && (
            <NcmReview
              searchResults={searchResults}
              selectedRow={selectedRow}
              onBack={handleBack}
            />
          )}

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1.5, mt: 4, pt: 2, borderTop: 1, borderColor: 'divider' }}>
            <Button
              variant="outlined"
              startIcon={<CancelIcon />}
              onClick={onClose}
              sx={{ borderRadius: 1.5 }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              loading={isSaving}
              onClick={handleSave}
              disabled={activeStep === 0 || !formData.ncm_code}
              sx={{ borderRadius: 1.5 }}
            >
              Save NCM
            </LoadingButton>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default NcmModal;
