import { useState, useEffect } from 'react';
import React from 'react';
import Box from '@mui/material/Box';
import EditableDataGrid from 'components/EditableDataGrid/EditableDataGrid';
import { useFetchData, useUpdateData } from 'services/queries'; // Updated import
import Card from 'components/card';
import { formatNcmCode } from 'utils/formatters/ncmFormatter';

const ListObjects = () => {
  const resourcePath = 'order/product-item-tax';
  const relatedResources = `order/product-item-tax`;

  // New queries for tax_calculation page
  const {
    data: salesOrders,
    isLoading: isLoadingSalesOrders,
    error: errorSalesOrders,
  } = useFetchData('order/simple-sales-order', true);
  const {
    data: productItems,
    isLoading: isLoadingProductItems,
    error: errorProductItems,
  } = useFetchData('order/product-item-tax', true);
  const {
    data: taxCalculations,
    isLoading: isLoadingTaxCalculations,
    error: errorTaxCalculations,
  } = useFetchData('tax/tax-calculation', true);
  const {
    data: ncmTec,
    isLoading: isLoadingNcmTec,
    error: errorNcmTec,
  } = useFetchData('tax/ncm-tec', true);

  const [tableData, setTableData] = useState([]);


  const calculateUpdate = useUpdateData('tax/tax-calculation/calculate');

  const handleCalculate = (productItemId) => {
    console.log('Calculating tax for product item:', productItemId);
    let id = productItemId
    calculateUpdate.mutate({ id });
  };

  // Merge data when all four queries have data
  useEffect(() => {
    if (salesOrders && productItems && taxCalculations && ncmTec) {
      const mergedData = productItems.map(item => {
        const order = salesOrders.find(o => o.order_identifier === item.order_identifier);
        const taxCalc = taxCalculations.find(t => t.id === item.tax_calculation);
        const ncmRecord = ncmTec.find(n => n.id === item.product?.ncm_tec);
        return {
          ...item,
          order_identifier: order?.order_identifier,
          tax_calculation: taxCalc,
          ncm_code: ncmRecord?.ncm_code,
        };
      });
      setTableData(() => mergedData);
    }
  }, [salesOrders, productItems, taxCalculations, ncmTec]);

  if (isLoadingSalesOrders || isLoadingProductItems || isLoadingTaxCalculations || isLoadingNcmTec)
    return <p>Loading...</p>;

  if (errorSalesOrders || errorProductItems || errorTaxCalculations || errorNcmTec)
    return <p>Error...</p>;

  // Currency formatter functions
  const formatUSD = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const formatBRL = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  // Helper functions to handle null/undefined values
  const formatNumberUSD = (value) => formatUSD.format(value || 0);
  const formatNumberBRL = (value) => formatBRL.format(value || 0);

  // Define columns with required fields
  const columns = [
    // Order Identifier from sales order
    {
      field: 'order_identifier',
      headerName: 'Order',
      minWidth: 90,
      flex: 1,
    },
    // Product Item columns
    {
      field: 'product_name',
      headerName: 'Product Name',
      minWidth: 250,
      flex: 1,
    },
    {
      field: 'ncm',
      headerName: 'NCM Code',
      minWidth: 120,
      flex: 1,
      valueFormatter: (value) => formatNcmCode(value)
    },
    {
      field: 'product_sku',
      headerName: 'SKU',
      minWidth: 120,
      flex: 1
    },
    // Tax Calculation fields
    {
      field: 'total_invoice_usd',
      headerName: 'Invoice (USD)',
      type: 'number',
      minWidth: 120,
      flex: 1,
      valueGetter: (value, row) => row.tax_calculation?.total_invoice_usd,
      valueFormatter: (value) => formatNumberUSD(value),
    },
    {
      field: 'total_invoice_brl',
      headerName: 'Invoice (BRL)',
      type: 'number',
      minWidth: 120,
      flex: 1,
      valueGetter: (value, row) => row.tax_calculation?.total_invoice_brl,
      valueFormatter: (value) => formatNumberBRL(value),
    },
    {
      field: 'seguro_internacional_usd',
      headerName: 'International Insurance (USD)',
      type: 'number',
      minWidth: 120,
      flex: 1,
      valueGetter: (value, row) => row.tax_calculation?.seguro_internacional_usd,
      valueFormatter: (value) => formatNumberUSD(value),
    },
    {
      field: 'seguro_internacional_brl',
      headerName: 'International Insurance (BRL)',
      type: 'number',
      minWidth: 120,
      flex: 1,
      valueGetter: (value, row) => row.tax_calculation?.seguro_internacional_brl,
      valueFormatter: (value) => formatNumberBRL(value),
    },
    {
      field: 'frete_internacional_usd',
      headerName: 'International Freight (USD)',
      type: 'number',
      minWidth: 120,
      flex: 1,
      valueGetter: (value, row) => row.tax_calculation?.frete_internacional_usd,
      valueFormatter: (value) => formatNumberUSD(value),
    },
    {
      field: 'frete_internacional_brl',
      headerName: 'International Freight (BRL)',
      type: 'number',
      minWidth: 120,
      flex: 1,
      valueGetter: (value, row) => row.tax_calculation?.frete_internacional_brl,
      valueFormatter: (value) => formatNumberBRL(value),
    },
    {
      field: 'valor_aduaneiro_brl',
      headerName: 'Customs Value (BRL)',
      type: 'number',
      minWidth: 140,
      flex: 1,
      valueGetter: (value, row) => row.tax_calculation?.valor_aduaneiro_brl,
      valueFormatter: (value) => formatNumberBRL(value),
    },
    {
      field: 'tus',
      headerName: 'TUS',
      type: 'number',
      minWidth: 100,
      flex: 1,
      valueGetter: (value, row) => row.tax_calculation?.tus,
      valueFormatter: (value) => formatNumberBRL(value),
    },
    {
      field: 'tributos_federais',
      headerName: 'Federal Taxes',
      type: 'number',
      minWidth: 140,
      flex: 1,
      valueGetter: (value, row) => row.tax_calculation?.tributos_federais,
      valueFormatter: (value) => formatNumberBRL(value),
    },
    {
      field: 'icms',
      headerName: 'ICMS',
      type: 'number',
      minWidth: 100,
      flex: 1,
      valueGetter: (value, row) => row.tax_calculation?.icms,
      valueFormatter: (value) => {
        if (value == null) return '0.00%';
        return `${(value * 100).toFixed(2)}%`;
      }
    },
    {
      field: 'icms_entrada',
      headerName: 'ICMS Entry',
      type: 'number',
      minWidth: 120,
      flex: 1,
      valueGetter: (value, row) => row.tax_calculation?.icms_entrada,
      valueFormatter: (value) => formatNumberBRL(value),
    }
    ,
    {
      field: 'bc_icms_garantia_ttd',
      headerName: 'BC ICMS Entry',
      type: 'number',
      minWidth: 120,
      flex: 1,
      valueGetter: (value, row) => row.tax_calculation?.bc_icms_garantia_ttd,
      valueFormatter: (value) => formatNumberBRL(value),
    },
    {
      field: 'icms_garantia',
      headerName: 'ICMS Guarantee',
      type: 'number',
      minWidth: 120,
      flex: 1,
      valueGetter: (value, row) => row.tax_calculation?.icms_garantia,
      valueFormatter: (value) => formatNumberBRL(value),
    },
    {
      field: 'base_ipi',
      headerName: 'IPI Base',
      type: 'number',
      minWidth: 100,
      flex: 1,
      valueGetter: (value, row) => row.tax_calculation?.base_ipi,
      valueFormatter: (value) => formatNumberBRL(value),
    },
    {
      field: 'ipi_entrada',
      headerName: 'IPI Entry',
      type: 'number',
      minWidth: 100,
      flex: 1,
      valueGetter: (value, row) => row.tax_calculation?.ipi_entrada,
      valueFormatter: (value) => formatNumberBRL(value),
    },
    {
      field: 'despesas_aduaneiras',
      headerName: 'Customs Expenses',
      type: 'number',
      minWidth: 160,
      flex: 1,
      valueGetter: (value, row) => row.tax_calculation?.despesas_aduaneiras,
      valueFormatter: (value) => formatNumberBRL(value),
    },
    {
      field: 'frete_rod_interno',
      headerName: 'Internal Freight',
      type: 'number',
      minWidth: 140,
      flex: 1,
      valueGetter: (value, row) => row.tax_calculation?.frete_rod_interno,
      valueFormatter: (value) => formatNumberBRL(value),
    },
    {
      field: 'base_calculo_venda',
      headerName: 'Sale Calculation Base',
      type: 'number',
      minWidth: 140,
      flex: 1,
      valueGetter: (value, row) => row.tax_calculation?.base_calculo_venda,
      valueFormatter: (value) => formatNumberBRL(value),
    },
    {
      field: 'icms_nf_destaque',
      headerName: 'ICMS NF Highlight',
      type: 'number',
      minWidth: 140,
      flex: 1,
      valueGetter: (value, row) => row.tax_calculation?.icms_nf_destaque,
      valueFormatter: (value) => formatNumberBRL(value),
    },
    {
      field: 'credito_presumido',
      headerName: 'Presumed Credit',
      type: 'number',
      minWidth: 140,
      flex: 1,
      valueGetter: (value, row) => row.tax_calculation?.credito_presumido,
      valueFormatter: (value) => formatNumberBRL(value),
    },
    {
      field: 'ipi_saida_destaque',
      headerName: 'IPI Output Highlight',
      type: 'number',
      minWidth: 140,
      flex: 1,
      valueGetter: (value, row) => row.tax_calculation?.ipi_saida_destaque,
      valueFormatter: (value) => formatNumberBRL(value),
    },
    {
      field: 'icms_st_destaque',
      headerName: 'ICMS ST Highlight',
      type: 'number',
      minWidth: 140,
      flex: 1,
      valueGetter: (value, row) => row.tax_calculation?.icms_st_destaque,
      valueFormatter: (value) => formatNumberBRL(value),
    },
    {
      field: 'pis_cofins_saida',
      headerName: 'PIS/COFINS Output',
      type: 'number',
      minWidth: 140,
      flex: 1,
      valueGetter: (value, row) => row.tax_calculation?.pis_cofins_saida,
      valueFormatter: (value) => formatNumberBRL(value),
    },
    {
      field: 'credito_impostos',
      headerName: 'Tax Credits',
      type: 'number',
      minWidth: 140,
      flex: 1,
      valueGetter: (value, row) => row.tax_calculation?.credito_impostos,
      valueFormatter: (value) => formatNumberBRL(value),
    },
    {
      field: 'acordo_icms',
      headerName: 'ICMS Agreement',
      type: 'number',
      minWidth: 120,
      flex: 1,
      valueGetter: (value, row) => row.tax_calculation?.acordo_icms,
      valueFormatter: (value) => formatNumberBRL(value),
    },
    {
      field: 'desemb_unitario',
      headerName: 'Unit Clearance',
      type: 'number',
      minWidth: 140,
      flex: 1,
      valueGetter: (value, row) => row.tax_calculation?.desemb_unitario,
      valueFormatter: (value) => formatNumberBRL(value),
    },
    {
      field: 'custo_unitario',
      headerName: 'Unit Cost',
      type: 'number',
      minWidth: 140,
      flex: 1,
      valueGetter: (value, row) => row.tax_calculation?.custo_unitario,
      valueFormatter: (value) => formatNumberBRL(value),
    },
    {
      field: 'acessoria',
      headerName: 'Advisory',
      type: 'number',
      minWidth: 120,
      flex: 1,
      valueGetter: (value, row) => row.tax_calculation?.acessoria,
      valueFormatter: (value) => formatNumberBRL(value),
    },

  ];

  const actions = {
    create: false,
    edit: false,
    delete: false,
    export: true,
    search: true,
  };

  return (
    <Card extra={'w-full h-full bg-white mt-3 p-3'}>
      <Box>
        <EditableDataGrid
          columns={columns}
          tableData={tableData}
          setTableData={setTableData}  // <-- Added to fix "setTableData is not a function" error
          resourcePath={resourcePath}
          relatedResources={relatedResources}
          actions={actions}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
            sorting: {
              sortModel: [{ field: 'order_identifier', sort: 'desc' }],
            },
          }}
          autosizeOptions={{
            includeOutliers: true,
            includeHeaders: true,
          }}
          autosizeOnMount={true}
        />
      </Box>
    </Card>
  );
};

export default ListObjects;
